import { useRouter } from "@app-routes";
import { config } from "@config/config";
import { SubVertical, Vertical } from "@gql-schema";
import { RequestPhoneSupportToggle } from "@shared/components/RequestPhoneSupportPopover/RequestPhoneSupportToggle";
import { CookieConsent } from "@shared/components/cookie-consent/cookie-consent";
import { AnalyticsService } from "@shared/services/AnalyticsService";
import { GCLID_NAME } from "@shared/utils/marketingProfile/saveMarketingProfile";
import { useSaveMarketingProfile } from "@shared/utils/marketingProfile/useSaveMarketingProfile";
import { ScrollElementContext } from "@ui/contexts/scrollElementContext";
import { Cookie } from "@uxf/core/cookie";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Analytics } from "./Analytics";
import { Favicon } from "./Favicon";
import { MetaTagData, MetaTagDataProps } from "./MetaTagData";
import { Prefetch } from "./Prefetch";
import { Preload } from "./Preload";

export interface LayoutBaseProps {
    hideSupportPopover?: boolean;
    metaTagData?: Partial<MetaTagDataProps>;
    replaceFullTitle?: boolean;
    subVertical?: SubVertical;
    title: string;
    vertical: Vertical;
}

const PROFILE_UUID_COOKIE_TTL = 60 * 60 * 24 * 365 * 5;

const LANDING_EVENTS_SENT = "landingProcessed";

export const LayoutBase: FC<PropsWithChildren<LayoutBaseProps>> = (props) => {
    const {
        children,
        title,
        metaTagData,
        vertical,
        subVertical = SubVertical.General,
        replaceFullTitle,
        hideSupportPopover,
    } = props;
    const { route, query } = useRouter();

    const [scrollElement, setScrollElement] = useState<HTMLElement | Window | null>(null);
    const saveMarketingProfile = useSaveMarketingProfile();

    // TODO @vejvis - HOTFIX!!!
    useEffect(() => {
        const cookie = Cookie.create();
        if (!cookie.has(config.PROFILE_UUID_HEADER)) {
            cookie.set(config.PROFILE_UUID_HEADER, uuidv4(), PROFILE_UUID_COOKIE_TTL);
        }
    }, []);

    useEffect(() => setScrollElement(window), []);

    useEffect(() => {
        if (route) {
            if (AnalyticsService.getBusVertical() !== vertical) {
                AnalyticsService.setBusVertical(vertical);
                saveMarketingProfile({ eventName: "Verticala", eventValueText1: vertical });
            }
        }
    }, [route, saveMarketingProfile, vertical]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!window.sessionStorage.getItem(LANDING_EVENTS_SENT)) {
                if (window.location.href) {
                    AnalyticsService.setLandingURL(window.location.href);
                    saveMarketingProfile({
                        eventName: "Vstupni stranka",
                        landingUrl: window.location.href,
                        eventValueText1: document.referrer,
                    });
                }
                AnalyticsService.setUtmParameters(query);
                if (query.utm_campaign || query.utm_source || query.utm_medium || query.utm_content) {
                    saveMarketingProfile({
                        eventName: "UTM",
                        utmCampaign: query.utm_campaign as string,
                        utmSource: query.utm_source as string,
                        utmMedium: query.utm_medium as string,
                    });
                }
                window.sessionStorage.setItem(LANDING_EVENTS_SENT, "true");
            }
            if (query.gclid) {
                window.localStorage.setItem(GCLID_NAME, query.gclid as string);
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [query, saveMarketingProfile]);

    return (
        <>
            <Prefetch />
            <Preload />
            <MetaTagData title={title} replaceFullTitle={replaceFullTitle} {...metaTagData} />
            <Analytics />
            <Favicon />
            <ScrollElementContext.Provider value={[scrollElement, setScrollElement]}>
                {children}
            </ScrollElementContext.Provider>
            {config.STAGE !== "local" && !hideSupportPopover && (
                <RequestPhoneSupportToggle subVertical={subVertical} vertical={vertical} />
            )}
            <CookieConsent />
        </>
    );
};
