import { Button, ButtonProps } from "@ui/components/Button/Button";
import { Icon } from "@ui/components/Icon/Icon";
import { COLORS } from "@ui/theme/colors";
import { TYPOGRAPHY } from "@ui/theme/typography";
import { ForwardRefRenderFunction, ReactNode, forwardRef, memo } from "react";

export interface NavBarLoginButtonProps extends Omit<ButtonProps, "title"> {
    menuKey?: string;
    variant?: "green" | "white" | "default";
    title?: ReactNode;
}

const BUTTON_COMMON_PROPS: Partial<Omit<ButtonProps, "title">> = {
    $fontSize: 14,
    $fontWeight: TYPOGRAPHY.fontWeight.semiBold,
    $height: 36,
    $lineHeight: `${20 / 14}`,
    $pl: 8,
    $pr: 0,
    $textPl: 8,
    $textPr: 14,
    $underline: false,
    $textAlign: "left",
    startIcon: <Icon name="user" size={24} />,
};

const Component: ForwardRefRenderFunction<HTMLAnchorElement, NavBarLoginButtonProps> = (props, ref) => {
    const { menuKey, title = "Zákaznická zóna", variant = "default", ...restProps } = props;

    if (variant === "green") {
        return (
            <Button
                {...BUTTON_COMMON_PROPS}
                $textColor={COLORS.yellow.default}
                data-menukey={menuKey}
                ref={ref}
                {...restProps}
            >
                {title}
            </Button>
        );
    }

    if (variant === "white") {
        return (
            <Button
                {...BUTTON_COMMON_PROPS}
                $color={COLORS.white}
                $focusColor={COLORS.white}
                $hoverColor={COLORS.yellow.default}
                $outlineBackgroundHoverColor={COLORS.yellow.default}
                $textHoverColor={COLORS.green.default}
                $textColor={COLORS.green.default}
                data-menukey={menuKey}
                ref={ref}
                {...restProps}
            >
                {title}
            </Button>
        );
    }

    return (
        <Button
            {...BUTTON_COMMON_PROPS}
            $color={COLORS.green.secondaryDark}
            $focusColor={COLORS.green.default}
            $hoverColor={COLORS.green.default}
            $textColor={COLORS.green.default}
            $textHoverColor={COLORS.white}
            data-menukey={menuKey}
            ref={ref}
            {...restProps}
        >
            {title}
        </Button>
    );
};

export const NavBarLoginButton = memo(forwardRef(Component));

NavBarLoginButton.displayName = "NavBarLoginButton";
