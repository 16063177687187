import { forwardRef, MouseEvent, ReactNode, useCallback } from "react";

interface Props {
    children?: ReactNode;
    id: string;
    onBackdropClick?: () => void;
}

export const NavBarModal = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { children, id, onBackdropClick } = props;

    const preventClose = useCallback((e: MouseEvent<HTMLDivElement>) => e.stopPropagation(), []);

    return (
        <div
            className="fixed inset-0 z-modal min-w-0 overflow-y-auto bg-black/50 outline-0"
            id={id}
            ref={ref}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="flex h-screen flex-col" onClick={onBackdropClick}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="bg-white shadow-shadow2" onClick={preventClose}>
                    {children}
                </div>
            </div>
        </div>
    );
});

NavBarModal.displayName = "NavBarModal";
