import { COLORS } from "@ui/theme/colors";
import { memo } from "react";
import { StyledClickable, StyledClickableProps } from "./styles/StyledClickable";

type Props = StyledClickableProps;

export const SiteLogoIcon = memo<Props>((props) => {
    const { $height = 43, $width = 53, title, ...restProps } = props;

    return (
        <StyledClickable $height={$height} $width={$width} {...restProps}>
            <span className="sr-only">{title}</span>
            <svg height="43" width="53" viewBox="0 0 53 43">
                <path
                    d="M0 43L8.7 9.3C10.2 3.8 15 0 20.5 0H53l-8.7 33.7C42.8 39.2 38 43 32.5 43H0z"
                    fill={COLORS.white}
                />
                <path
                    d="M20.5 3.3c-4.1 0-7.6 2.8-8.7 6.9l-2.2 8.6h8.3l2.2-8.6h5.4l-5.9 22.9h-5.4l2.2-8.6H8.1L4.1 40h28.4c4.1 0 7.6-2.8 8.7-6.9l7.7-29.6H20.5z"
                    fill={COLORS.green.default}
                />
                <path d="M34.3 32.9H28l-5.4-11.4L34 10.1h6.3L28.9 21.5l5.4 11.4z" fill={COLORS.yellow.default} />
            </svg>
        </StyledClickable>
    );
});

SiteLogoIcon.displayName = "SiteLogo";
