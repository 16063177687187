import { ClickableProps } from "@ui/components/Clickable/Clickable";
import { IconLink } from "@ui/components/IconLink/IconLink";
import { COLORS } from "@ui/theme/colors";
import { Margins } from "@uxf/styles/properties/margins";
import { forwardRef, ForwardRefRenderFunction, memo } from "react";

export interface NavBarMenuTriggerProps extends ClickableProps, Margins {
    isOpen?: boolean;
    variant?: "white" | "black" | "default";
}

const NavBarMenuTriggerComponent: ForwardRefRenderFunction<HTMLAnchorElement, NavBarMenuTriggerProps> = (
    props,
    ref,
) => {
    const { isOpen, variant = "default", ...restProps } = props;

    const title = isOpen ? "Zavřít menu" : "Otevřít menu";

    const icon = isOpen ? "cross" : "menu";
    const iconSize = isOpen ? 14 : undefined;

    if (variant === "white") {
        return (
            <IconLink
                $backgroundHoverColor={COLORS.yellow.default}
                $color="white"
                $hoverColor={COLORS.green.default}
                iconSize={iconSize}
                name={icon}
                ref={ref}
                size={18}
                title={title}
                {...restProps}
            />
        );
    }

    if (variant === "black") {
        return (
            <IconLink
                $backgroundHoverColor={COLORS.green.dark}
                $color={COLORS.black}
                $focusColor={COLORS.green.default}
                $hoverColor={COLORS.yellow.default}
                iconSize={iconSize}
                name={icon}
                ref={ref}
                size={18}
                title={title}
                {...restProps}
            />
        );
    }

    return <IconLink iconSize={iconSize} name={icon} ref={ref} size={18} title={title} {...restProps} />;
};

export const NavBarMenuTrigger = memo(forwardRef(NavBarMenuTriggerComponent));

NavBarMenuTrigger.displayName = "NavBarMenuTrigger";
