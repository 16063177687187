import { type NavbarConfigSectionColumnFragment } from "@gql-schema";
import { MobileSubmenuDropDownLevel1Title } from "./mobile-submenu-drop-down-level1-title";

interface MobileSubmenuDropDownLevel1Props {
    data: NavbarConfigSectionColumnFragment;
}

export function MobileSubmenuDropDownLevel1(props: MobileSubmenuDropDownLevel1Props) {
    const hasData = props.data.items.length > 0;

    if (!hasData) {
        return null;
    }
    return (
        <div className="first:pt-2 last:pt-2">
            <MobileSubmenuDropDownLevel1Title label={props.data.items[0].label} href={props.data.items[0].href} />
        </div>
    );
}
