import { useKey } from "@uxf/core/hooks/useKey";
import { RefObject, useCallback } from "react";

export const useCloseToggleLayerOnEscKey = <MENU_ELEMENT extends HTMLElement, TRIGGER_ELEMENT extends HTMLElement>(
    isOpen: boolean,
    close: () => void,
    menuRef: RefObject<MENU_ELEMENT>,
    triggerRef: RefObject<TRIGGER_ELEMENT>,
) => {
    const handleCloseOnEscKey = useCallback(
        (e: KeyboardEvent) => {
            const menuNode = menuRef.current;
            const activeNode = document.activeElement;

            if (isOpen && menuNode && (menuNode === activeNode || menuNode.contains(activeNode))) {
                e.preventDefault();
                close();

                const triggerNode = triggerRef.current;

                // put focus back to trigger
                if (triggerNode) {
                    triggerNode.focus();
                }
            }
        },
        [isOpen, close, menuRef, triggerRef],
    );

    useKey("Escape", handleCloseOnEscKey, { targetRef: menuRef, type: "keyup" });
};
