import { type NavbarConfigSectionFragment } from "@gql-schema";
import { MobileSubmenuDropDownLevel1 } from "./mobile-submenu-drop-down-level1";

interface MobileSubmenuDropDownProps {
    data: NavbarConfigSectionFragment;
}

export function MobileSubmenuDropDown(props: MobileSubmenuDropDownProps) {
    return (
        <ul>
            {props.data.columns.map((item, index) => (
                <MobileSubmenuDropDownLevel1 data={item} key={index} />
            ))}
        </ul>
    );
}
