import { Clickable, ClickableProps } from "@ui/components/Clickable/Clickable";
import { disabledElementStyle } from "@ui/mixins/disabledElementStyle";
import { focusShadow } from "@ui/mixins/focusShadow";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { COLORS } from "@ui/theme/colors";
import { TYPOGRAPHY } from "@ui/theme/typography";
import { CLASSES } from "@uxf/core/constants/classes";
import { injectCss } from "@uxf/styles/mixins/injectCss";
import { Margins } from "@uxf/styles/properties/margins";
import { Paddings } from "@uxf/styles/properties/paddings";
import { ColorProperty, ResponsiveProperty } from "@uxf/styles/types";
import { formatResponsiveValue } from "@uxf/styles/utils/formatValue";
import { rem } from "@uxf/styles/utils/rem";
import { resolveResponsiveProperties } from "@uxf/styles/utils/responsive";
import { transition } from "@uxf/styles/utils/transition";
import { Property } from "csstype";
import styled, { FlattenSimpleInterpolation, css } from "styled-components";

export interface MobileMenuItemClickableProps
    extends ClickableProps,
        Pick<Margins, "$mb" | "$mt" | "$my">,
        Pick<Paddings, "$pl"> {
    $backgroundColor?: ColorProperty;
    $color?: ColorProperty;
    $css?: FlattenSimpleInterpolation;
    $cursor?: Property.Cursor;
    $height?: ResponsiveProperty.Height;
    $isFullWidth?: boolean;
    $minHeight?: ResponsiveProperty.MinHeight;
    $textTransform?: Property.TextTransform;
}

export const MobileMenuItemClickable = styled(Clickable)<MobileMenuItemClickableProps>(
    ({
        $backgroundColor,
        $color = COLORS.neutral["600"],
        $css,
        $cursor = "pointer",
        $height,
        $isFullWidth,
        $mb,
        $minHeight = 48,
        $mt,
        $my,
        $pl,
        $textTransform = "uppercase",
    }) => css`
        align-items: center;
        background-color: ${$backgroundColor};
        color: ${$color};
        display: flex;
        max-width: 100%;
        padding: ${rem(4)} ${rem(24)};
        text-decoration: none;
        text-transform: ${$textTransform};
        transition: ${transition(["backgroundColor", "color"], 100)};

        ${$isFullWidth &&
        css`
            width: 100%;
        `}

        ${resolveResponsiveProperties(BREAKPOINTS, {
            height: formatResponsiveValue($height),
            marginBottom: formatResponsiveValue($mb ?? $my),
            marginTop: formatResponsiveValue($mt ?? $my),
            paddingLeft: formatResponsiveValue($pl),
            minHeight: formatResponsiveValue($minHeight),
        })};

        &:focus {
            outline: none;
        }

        &.${CLASSES.IS_ACTIVE} {
            color: ${COLORS.green.default};
            font-weight: ${TYPOGRAPHY.fontWeight.bold};
        }

        &.${CLASSES.IS_HOVERABLE} {
            cursor: ${$cursor};

            &:active,
            &:hover {
                background-color: ${COLORS.green.default};
                color: ${COLORS.white};
            }
        }

        &.${CLASSES.FOCUS_VISIBLE} {
            .mobile-menu-title {
                ${focusShadow(COLORS.green.default)};
            }
        }

        &.${CLASSES.IS_DISABLED} {
            ${disabledElementStyle};
        }

        ${injectCss($css)}
    `,
);
