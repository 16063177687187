import { Button, ButtonProps } from "@ui/components/Button/Button";
import { Icon } from "@ui/components/Icon/Icon";
import { COLORS } from "@ui/theme/colors";
import { TYPOGRAPHY } from "@ui/theme/typography";
import { ReactNode, memo } from "react";

interface Props extends Omit<ButtonProps, "title"> {
    menuKey?: string;
    title: ReactNode;
}

export const NavBarLoginButtonMobile = memo<Props>((props) => {
    const { menuKey, title = "Registrace", ...restProps } = props;

    return (
        <Button
            $color={COLORS.green.secondaryDark}
            $focusColor={COLORS.green.default}
            $fontSize={14}
            $fontWeight={TYPOGRAPHY.fontWeight.semiBold}
            $height={44}
            $hoverColor={COLORS.green.default}
            $lineHeight={(20 / 14).toString()}
            $pl={8}
            $pr={0}
            $textAlign="left"
            $textColor={COLORS.green.default}
            $textHoverColor={COLORS.white}
            $textPl={8}
            $textPr={14}
            data-menukey={menuKey}
            startIcon={<Icon name="user" size={24} />}
            {...restProps}
        >
            {title}
        </Button>
    );
});

NavBarLoginButtonMobile.displayName = "NavBarLoginButtonMobile";
