import { CSSProperties, forwardRef, ReactNode } from "react";

interface Props {
    children?: ReactNode;
    id?: string;
    style?: CSSProperties;
}

export const NavBarPopoverContainer = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <div
            className="z-dropdown w-[300px] min-w-0 overflow-hidden rounded-2xl bg-white shadow-shadow2 outline-0"
            id={props.id}
            ref={ref}
            style={props.style}
            tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        >
            {props.children}
        </div>
    );
});

NavBarPopoverContainer.displayName = "NavBarPopoverContainer";
