import { Clickable, ClickableProps } from "@ui/components/Clickable/Clickable";
import { disabledElementStyle } from "@ui/mixins/disabledElementStyle";
import { focusShadow } from "@ui/mixins/focusShadow";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { COLORS } from "@ui/theme/colors";
import { CLASSES } from "@uxf/core/constants/classes";
import { ColorProperty, ResponsiveProperty } from "@uxf/styles/types";
import { formatResponsiveValue } from "@uxf/styles/utils/formatValue";
import { resolveResponsiveProperties } from "@uxf/styles/utils/responsive";
import styled, { css } from "styled-components";

export interface StyledClickableProps extends ClickableProps {
    $focusColor?: ColorProperty;
    $height?: ResponsiveProperty.Height;
    $width?: ResponsiveProperty.Width;
}

export const StyledClickable = styled(Clickable)<StyledClickableProps>(
    ({ $focusColor = COLORS.green.lighter, $height, $width }) => css`
        border-style: none;
        text-decoration: none;
        display: inline-flex;

        ${resolveResponsiveProperties(BREAKPOINTS, {
            height: formatResponsiveValue($height),
            width: formatResponsiveValue($width),
        })};

        &:focus {
            outline: none;
        }

        &.${CLASSES.FOCUS_VISIBLE} {
            ${focusShadow($focusColor)};
        }

        &.${CLASSES.IS_HOVERABLE} {
            cursor: pointer;
        }

        &.${CLASSES.IS_DISABLED} {
            ${disabledElementStyle};
        }

        &.${CLASSES.IS_LOADING} {
            cursor: progress;
        }
    `,
);
