import { useRouter } from "@app-routes";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

export function useBooleanWithRouteTransition(initialValue?: boolean): [boolean, Dispatch<SetStateAction<boolean>>] {
    const [isOpen, setIsOpen] = useState<boolean>(initialValue || false);

    const { events } = useRouter();
    const eventsRef = useRef(events);

    useEffect(() => {
        if (isOpen) {
            const setToFalse = () => setIsOpen(false);
            const thisEvents = eventsRef.current;
            thisEvents.on("routeChangeStart", setToFalse);
            return () => {
                thisEvents.off("routeChangeStart", setToFalse);
            };
        }
    }, [isOpen]);

    return [isOpen, setIsOpen];
}
