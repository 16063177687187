import { SubVertical, Vertical } from "@gql-schema";
import { Icon } from "@ui/components/Icon/Icon";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { useClickableProps } from "@uxf/core/hooks/useClickableProps";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import { useMinWindowWidth } from "@uxf/core/hooks/useMinWindowWidth";
import dynamic from "next/dynamic";
import { FC, HTMLAttributes, PropsWithChildren, memo, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { RequestPhoneSupportPopoverProps } from "./RequestPhoneSupportPopover";
import { FloatingButton } from "./styles/FloatingButton";
import { ringAnimationStyle } from "./styles/ringAnimationStyle";

const Popover = dynamic<RequestPhoneSupportPopoverProps>(
    () => import("./RequestPhoneSupportPopover").then((component) => component.RequestPhoneSupportPopover),
    {
        ssr: false,
    },
);

const DISMISS_CALLBACK_REQUEST_POPOVER = "dismiss-callback-request-popover";

interface Props {
    vertical: Vertical;
    subVertical: SubVertical;
}

const Component: FC<PropsWithChildren<Props>> = ({ subVertical, vertical }) => {
    const [showPopover, setShowPopover] = useState(false);

    const isDesktop = useMinWindowWidth(BREAKPOINTS.md);

    const togglePopover = useCallback(() => {
        if (showPopover) {
            sessionStorage.setItem(DISMISS_CALLBACK_REQUEST_POPOVER, "true");
        }
        setShowPopover(!showPopover);
    }, [showPopover]);

    const clickableProps = useClickableProps<HTMLAttributes<HTMLSpanElement>>({ onClick: togglePopover });

    return isDesktop
        ? createPortal(
              <>
                  {showPopover && (
                      <Popover subVertical={subVertical} togglePopover={togglePopover} vertical={vertical} />
                  )}
                  <FloatingButton {...clickableProps}>
                      <Icon $css={ringAnimationStyle} name="phoneDial" size={34} />
                      <span className="sr-only">
                          {showPopover ? "Skrýt" : "Zobrazit"} žádost o telefonickou podporu
                      </span>
                  </FloatingButton>
              </>,
              document.body,
          )
        : null;
};

export const RequestPhoneSupportToggle = memo<Props>((props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Component {...props} /> : null;
});

RequestPhoneSupportToggle.displayName = "RequestPhoneSupportToggle";
