import { css, keyframes } from "styled-components";

const ringAnimation = keyframes`
  0%, 17%, 100% { transform: rotate(0); }
  2% { transform: rotate(20deg) }
  4% { transform: rotate(-18deg) }
  6% { transform: rotate(22deg) }
  8% { transform: rotate(-20deg) }
  10% { transform: rotate(20deg) }
  12% { transform: rotate(-15deg) }
  14% { transform: rotate(10deg) }
  16% { transform: rotate(-10deg) }
`;

export const ringAnimationStyle = css`
    animation-name: ${ringAnimation};
    animation-duration: 6s;
    animation-delay: 3s;
    animation-iteration-count: 2;
`;
