import { cover } from "@ui/mixins/cover";
import { focusShadow } from "@ui/mixins/focusShadow";
import { COLORS } from "@ui/theme/colors";
import { shade } from "@ui/utils/shade";
import { CLASSES } from "@uxf/core/constants/classes";
import { rem } from "@uxf/styles/utils/rem";
import { HTMLAttributes } from "react";
import styled from "styled-components";

export const FloatingButton = styled("span")<HTMLAttributes<HTMLSpanElement>>`
    align-items: center;
    background-color: ${COLORS.green.default};
    border: 2px solid currentcolor;
    border-radius: 50%;
    bottom: ${rem(32)};
    color: ${COLORS.white};
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: ${rem(64)};
    position: fixed;
    right: ${rem(32)};
    width: ${rem(64)};
    z-index: 30001;

    &::before,
    &::after {
        background-color: inherit;
        border-radius: inherit;
        content: "";
        display: block;
        opacity: 0.25;
        z-index: -1;
    }

    &::before {
        ${cover(-20)};
    }

    &::after {
        ${cover(-10)};
    }

    &:focus {
        outline-style: none;
    }

    &.${CLASSES.FOCUS_VISIBLE} {
        ${focusShadow(COLORS.green.default)};
    }

    &[aria-disabled="true"] {
        opacity: ${CLASSES.IS_DISABLED};
        outline: 0;
        pointer-events: none;
    }

    &.${CLASSES.IS_HOVERABLE} {
        cursor: pointer;

        &:active,
        &:hover {
            background-color: ${shade(COLORS.green.default, 0.15)};
            color: ${COLORS.white};

            &::before {
                ${cover(-22)};
            }

            &::after {
                ${cover(-12)};
            }
        }
    }
`;
