import { ApolloClient } from "@apollo/client";
import { NormalizedCacheObject } from "@apollo/client/cache";
import { Router as UxfRouter } from "@uxf/router";
import { NextComponentType, NextPageContext } from "next";
import { LinkProps as NextLinkProps } from "next/link";
import UAParser from "ua-parser-js";
import { Routes, routes } from "./routes";

export interface Options {
    anchor?: string;
    shallow?: boolean;

    [key: string]: any;
}

const router = new UxfRouter<Routes>(routes);

export const Router = router.getSingletonRouter();
export const useRouter = router.getUseRouter();
export const getUrlByRoute = router.getUrlByRoute;
export const getRoutesForSitemap = () => routes;
export type RouteList = Routes;
export type RouteName = keyof Routes;

// TODO Vláďa: POUZE HOTFIX ŘEŠENÍ
export type LinkProps<T = Routes, R extends keyof T = keyof T> = Omit<
    NextLinkProps,
    "as" | "href" | "onMouseEnter" | "onClick"
> & {
    route: R;
    params?: T[R] & { generate_pdf?: boolean };
};

export const Link = router.getLink();

type Filter<T> = {
    [K in keyof T]: T[K] extends null
        ? { route: K; params?: never }
        : {
              route: K;
              params: T[K];
          };
}[keyof T];

export type RouteProps = Filter<RouteList>;

// TODO @vejvis - co s tim?
export interface UxfPageContext<T extends keyof RouteList> extends Omit<NextPageContext, "query"> {
    apolloClient: ApolloClient<NormalizedCacheObject>;
    query: RouteList[T] extends null
        ? Record<string, any>
        : { [key in keyof RouteList[T]]: string | string[] | undefined };
    uaParsed: UAParser; // TODO - tohle tu nemá být
    abTestingVariant: string; // TODO @vejvis - tohle tu nemá být
}

export type NextPage<Route extends keyof RouteList, IP = Record<string, any>, P = IP> = NextComponentType<
    UxfPageContext<Route>,
    IP,
    P
>;
