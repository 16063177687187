import { useRouter } from "@app-routes";
import { LoginTypeEnum, MyProfileFragment } from "@gql-schema";
import { useLoginTypeMutation } from "@gql-schema/AuthMutations";
import { AnalyticsService } from "@shared/services/AnalyticsService";
import { ErrorService } from "@shared/services/ErrorService";
import { useCallback } from "react";

export function useOnLogin(profile?: MyProfileFragment | null) {
    const { push } = useRouter();
    const [login] = useLoginTypeMutation();

    return useCallback(async () => {
        const email = profile?.email;
        if (!email) {
            return;
        }

        try {
            const { data } = await login({ variables: { email } });

            const loginType = data?.loginType.type;

            if (loginType === LoginTypeEnum.MagicklinkSent) {
                AnalyticsService.uiEvent("Button", "Click", "Navbar_Prihlaseni_Odkaz")();
                await push("customer-zone/link-sent", { email });
            } else if (loginType === LoginTypeEnum.PasswordRequired) {
                AnalyticsService.uiEvent("Button", "Click", "Navbar_Prihlaseni_Heslo")();
                await push("customer-zone/login-password", { email });
            }
        } catch (e) {
            ErrorService.handleError(e);
        }
    }, [login, profile?.email, push]);
}
