import { useBooleanWithRouteTransition } from "@ui/hooks/useBooleanWithRouteTransition";
import { useCloseToggleLayerOnEscKey } from "@ui/hooks/useCloseToggleLayerOnEscKey";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { getResizeObserver } from "@ui/utils/getResizeObserver";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import { useMinWindowWidth } from "@uxf/core/hooks/useMinWindowWidth";
import { composeRefs } from "@uxf/core/utils/composeRefs";
import { FC, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useLayer } from "react-laag";
import { NavBarMenuTrigger, NavBarMenuTriggerProps } from "./nav-bar-menu-trigger";
import { NavBarPopoverContainer } from "./styles/nav-bar-popover-container";

export interface NavBarBurgerMenuProps extends Omit<NavBarMenuTriggerProps, "onClick" | "params" | "route"> {
    menuId: string;
}

export const NavBarBurgerMenu: FC<PropsWithChildren<NavBarBurgerMenuProps>> = (props) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLAnchorElement>(null);

    const { children, menuId, ...restProps } = props;

    const [isOpen, setIsOpen] = useBooleanWithRouteTransition(false);

    const close = useCallback(() => setIsOpen(false), [setIsOpen]);
    const toggle = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);

    const isMounted = useIsMounted();
    const isNotMobile = useMinWindowWidth(BREAKPOINTS.md);
    const isDesktop = useMinWindowWidth(BREAKPOINTS.xl);

    const { renderLayer, triggerProps, layerProps } = useLayer({
        auto: true,
        containerOffset: 16,
        isOpen: isOpen,
        onDisappear: (type) => type === "full" && close(),
        onOutsideClick: close,
        overflowContainer: false,
        possiblePlacements: ["bottom-end"],
        preferX: "left",
        snap: true,
        triggerOffset: 8,
        ResizeObserver: getResizeObserver(),
    });

    useCloseToggleLayerOnEscKey(isOpen, close, dropdownRef, triggerRef);

    useEffect(() => {
        const node = dropdownRef.current;
        if (node) {
            node.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        if ((isDesktop || !isNotMobile) && isOpen) {
            close();
        }
    }, [close, isDesktop, isNotMobile, isOpen]);

    return (
        <>
            <NavBarMenuTrigger
                aria-controls={menuId}
                aria-haspopup
                aria-expanded={isOpen}
                ref={composeRefs(triggerRef, triggerProps.ref)}
                onClick={toggle}
                {...restProps}
            />
            {isMounted &&
                isOpen &&
                renderLayer(
                    <NavBarPopoverContainer
                        id={menuId}
                        ref={composeRefs(dropdownRef, layerProps.ref)}
                        style={layerProps.style}
                    >
                        {children}
                    </NavBarPopoverContainer>,
                )}
        </>
    );
};
