import { getUrlByRoute } from "@app-routes";
import { IconsSet } from "@config/icons";
import { ClickableProps } from "@ui/components/Clickable/Clickable";

export interface MenuItemData<MenuKey = string> {
    analyticsCallback?: () => void;
    href?: ClickableProps["href"];
    iconName?: IconsSet;
    menuKey: MenuKey;
    params?: ClickableProps["params"];
    route?: ClickableProps["route"];
    title: string;
}

export const USER_MENU: Record<string, MenuItemData> = {
    login: {
        title: "Přihlášení",
        route: "customer-zone/login-email",
        menuKey: "prihlaseni",
    },
    logout: {
        title: "Odhlásit se",
        href: getUrlByRoute("logout", {}),
        menuKey: "zakaznicka-zona/odhlaseni",
        iconName: "exit",
    },
    registration: {
        title: "Registrace",
        route: "customer-zone/registration",
        menuKey: "registrace",
    },
    userSettings: {
        title: "Nastavení účtu",
        route: "customer-zone/personal-data",
        menuKey: "zakaznicka-zona/nastaveni-uctu",
        iconName: "gear",
    },
};

export const CUSTOMER_ZONE_MENU_ITEM: MenuItemData = {
    title: "Zákaznická zóna",
    route: "customer-zone/calculations",
    menuKey: "zakaznicka-zona/vase-srovnani",
    iconName: "website",
};

export const CUSTOMER_ZONE_MENU: MenuItemData[] = [
    {
        title: "Kalkulace",
        route: "customer-zone/calculations",
        menuKey: "zakaznicka-zona/vase-srovnani",
        iconName: "scales",
    },
    {
        title: "Smlouvy",
        route: "customer-zone/contracts",
        menuKey: "zakaznicka-zona/smlouvy",
        iconName: "invoice",
    },
    {
        title: "Odměny",
        route: "customer-zone/benefits",
        menuKey: "zakaznicka-zona/odmeny",
        iconName: "voucher",
    },
    {
        title: "Hlídací vlk",
        route: "customer-zone/watchwolf",
        menuKey: "zakaznicka-zona/hlidaci-vlk",
        iconName: "wolfSmall",
    },
];
