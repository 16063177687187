import { ICON_SPRITE, ICONS_VERSION } from "@config/icons-config";
import Head from "next/head";
import { FC, memo } from "react";

const PrefetchComponent: FC = () => {
    return (
        <Head>
            <link rel="preconnect" href="https://www.googletagmanager.com" />
            <link rel="preconnect" href="https://www.google-analytics.com" />
            <link rel="preconnect" href="https://c.imedia.cz" />
            <link rel="preconnect" href="https://connect.facebook.net" />
            <link
                as="image"
                crossOrigin="anonymous"
                href={`${ICON_SPRITE}?v=${ICONS_VERSION}`}
                rel="prefetch"
                type="image/svg+xml"
            />
        </Head>
    );
};

export const Prefetch = memo(PrefetchComponent);

Prefetch.displayName = "Prefetch";
