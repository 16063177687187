/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { MyProfileFragmentDoc, AdminProfileFragmentDoc } from './ProfileFragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const RegistrationDocument = gql`
    mutation Registration($data: RegistrationInput!) {
  registration(data: $data)
}
    `;
export type RegistrationMutationFn = Apollo.MutationFunction<Types.RegistrationMutation, Types.RegistrationMutationVariables>;

/**
 * __useRegistrationMutation__
 *
 * To run a mutation, you first call `useRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationMutation, { data, loading, error }] = useRegistrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RegistrationMutation, Types.RegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RegistrationMutation, Types.RegistrationMutationVariables>(RegistrationDocument, options);
      }
export type RegistrationMutationHookResult = ReturnType<typeof useRegistrationMutation>;
export type RegistrationMutationResult = Apollo.MutationResult<Types.RegistrationMutation>;
export type RegistrationMutationOptions = Apollo.BaseMutationOptions<Types.RegistrationMutation, Types.RegistrationMutationVariables>;
export const RegistrationByEmailDocument = gql`
    mutation RegistrationByEmail($data: RegistrationByEmailInput!) {
  registrationByEmail(data: $data) {
    success
  }
}
    `;
export type RegistrationByEmailMutationFn = Apollo.MutationFunction<Types.RegistrationByEmailMutation, Types.RegistrationByEmailMutationVariables>;

/**
 * __useRegistrationByEmailMutation__
 *
 * To run a mutation, you first call `useRegistrationByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationByEmailMutation, { data, loading, error }] = useRegistrationByEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationByEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.RegistrationByEmailMutation, Types.RegistrationByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RegistrationByEmailMutation, Types.RegistrationByEmailMutationVariables>(RegistrationByEmailDocument, options);
      }
export type RegistrationByEmailMutationHookResult = ReturnType<typeof useRegistrationByEmailMutation>;
export type RegistrationByEmailMutationResult = Apollo.MutationResult<Types.RegistrationByEmailMutation>;
export type RegistrationByEmailMutationOptions = Apollo.BaseMutationOptions<Types.RegistrationByEmailMutation, Types.RegistrationByEmailMutationVariables>;
export const CreateMagicLinkDocument = gql`
    mutation CreateMagicLink($email: String!) {
  createMagicLink(email: $email)
}
    `;
export type CreateMagicLinkMutationFn = Apollo.MutationFunction<Types.CreateMagicLinkMutation, Types.CreateMagicLinkMutationVariables>;

/**
 * __useCreateMagicLinkMutation__
 *
 * To run a mutation, you first call `useCreateMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMagicLinkMutation, { data, loading, error }] = useCreateMagicLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateMagicLinkMutation, Types.CreateMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateMagicLinkMutation, Types.CreateMagicLinkMutationVariables>(CreateMagicLinkDocument, options);
      }
export type CreateMagicLinkMutationHookResult = ReturnType<typeof useCreateMagicLinkMutation>;
export type CreateMagicLinkMutationResult = Apollo.MutationResult<Types.CreateMagicLinkMutation>;
export type CreateMagicLinkMutationOptions = Apollo.BaseMutationOptions<Types.CreateMagicLinkMutation, Types.CreateMagicLinkMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password)
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>;
export const SendMeMagicLinkDocument = gql`
    mutation SendMeMagicLink {
  sendMeMagicLink
}
    `;
export type SendMeMagicLinkMutationFn = Apollo.MutationFunction<Types.SendMeMagicLinkMutation, Types.SendMeMagicLinkMutationVariables>;

/**
 * __useSendMeMagicLinkMutation__
 *
 * To run a mutation, you first call `useSendMeMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMeMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMeMagicLinkMutation, { data, loading, error }] = useSendMeMagicLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendMeMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendMeMagicLinkMutation, Types.SendMeMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendMeMagicLinkMutation, Types.SendMeMagicLinkMutationVariables>(SendMeMagicLinkDocument, options);
      }
export type SendMeMagicLinkMutationHookResult = ReturnType<typeof useSendMeMagicLinkMutation>;
export type SendMeMagicLinkMutationResult = Apollo.MutationResult<Types.SendMeMagicLinkMutation>;
export type SendMeMagicLinkMutationOptions = Apollo.BaseMutationOptions<Types.SendMeMagicLinkMutation, Types.SendMeMagicLinkMutationVariables>;
export const PasswordChangeDocument = gql`
    mutation passwordChange($password: String!) {
  passwordChange(password: $password) {
    ...MyProfile
  }
}
    ${MyProfileFragmentDoc}`;
export type PasswordChangeMutationFn = Apollo.MutationFunction<Types.PasswordChangeMutation, Types.PasswordChangeMutationVariables>;

/**
 * __usePasswordChangeMutation__
 *
 * To run a mutation, you first call `usePasswordChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeMutation, { data, loading, error }] = usePasswordChangeMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function usePasswordChangeMutation(baseOptions?: Apollo.MutationHookOptions<Types.PasswordChangeMutation, Types.PasswordChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PasswordChangeMutation, Types.PasswordChangeMutationVariables>(PasswordChangeDocument, options);
      }
export type PasswordChangeMutationHookResult = ReturnType<typeof usePasswordChangeMutation>;
export type PasswordChangeMutationResult = Apollo.MutationResult<Types.PasswordChangeMutation>;
export type PasswordChangeMutationOptions = Apollo.BaseMutationOptions<Types.PasswordChangeMutation, Types.PasswordChangeMutationVariables>;
export const PasswordChangeEmailDocument = gql`
    mutation passwordChangeEmail($email: String!) {
  passwordChangeEmail(email: $email) {
    success
  }
}
    `;
export type PasswordChangeEmailMutationFn = Apollo.MutationFunction<Types.PasswordChangeEmailMutation, Types.PasswordChangeEmailMutationVariables>;

/**
 * __usePasswordChangeEmailMutation__
 *
 * To run a mutation, you first call `usePasswordChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeEmailMutation, { data, loading, error }] = usePasswordChangeEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordChangeEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.PasswordChangeEmailMutation, Types.PasswordChangeEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PasswordChangeEmailMutation, Types.PasswordChangeEmailMutationVariables>(PasswordChangeEmailDocument, options);
      }
export type PasswordChangeEmailMutationHookResult = ReturnType<typeof usePasswordChangeEmailMutation>;
export type PasswordChangeEmailMutationResult = Apollo.MutationResult<Types.PasswordChangeEmailMutation>;
export type PasswordChangeEmailMutationOptions = Apollo.BaseMutationOptions<Types.PasswordChangeEmailMutation, Types.PasswordChangeEmailMutationVariables>;
export const LoginTypeDocument = gql`
    mutation loginType($email: String!) {
  loginType(email: $email) {
    type
  }
}
    `;
export type LoginTypeMutationFn = Apollo.MutationFunction<Types.LoginTypeMutation, Types.LoginTypeMutationVariables>;

/**
 * __useLoginTypeMutation__
 *
 * To run a mutation, you first call `useLoginTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginTypeMutation, { data, loading, error }] = useLoginTypeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.LoginTypeMutation, Types.LoginTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LoginTypeMutation, Types.LoginTypeMutationVariables>(LoginTypeDocument, options);
      }
export type LoginTypeMutationHookResult = ReturnType<typeof useLoginTypeMutation>;
export type LoginTypeMutationResult = Apollo.MutationResult<Types.LoginTypeMutation>;
export type LoginTypeMutationOptions = Apollo.BaseMutationOptions<Types.LoginTypeMutation, Types.LoginTypeMutationVariables>;