import Head from "next/head";
import { FC, memo } from "react";

const PreloadComponent: FC = () => {
    return (
        <Head>
            <link
                as="font"
                href="/static/fonts/poppins/poppins-v15-latin-ext_latin-regular.woff2"
                rel="preload"
                crossOrigin="anonymous"
            />
            <link
                as="font"
                href="/static/fonts/poppins/poppins-v15-latin-ext_latin-italic.woff2"
                rel="preload"
                crossOrigin="anonymous"
            />
            <link
                as="font"
                href="/static/fonts/poppins/poppins-v15-latin-ext_latin-500.woff2"
                rel="preload"
                crossOrigin="anonymous"
            />
            <link
                as="font"
                href="/static/fonts/poppins/poppins-v15-latin-ext_latin-500italic.woff2"
                rel="preload"
                crossOrigin="anonymous"
            />
            <link
                as="font"
                href="/static/fonts/poppins/poppins-v15-latin-ext_latin-600.woff2"
                rel="preload"
                crossOrigin="anonymous"
            />
            <link
                as="font"
                href="/static/fonts/poppins/poppins-v15-latin-ext_latin-700.woff2"
                rel="preload"
                crossOrigin="anonymous"
            />
        </Head>
    );
};

export const Preload = memo(PreloadComponent);

Preload.displayName = "Preload";
