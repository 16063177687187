import type { ReactNode } from "react";

type Props = { className?: string; children: ReactNode };

export function NavBarContainerDesktop(props: Props) {
    return (
        <div
            className={`container mx-auto hidden h-[72px] w-full items-center justify-between md:flex xl:h-[92px] ${
                props.className ?? ""
            }`}
        >
            {props.children}
        </div>
    );
}
