import { CUSTOMER_ZONE_MENU_ITEM, USER_MENU } from "@config/configureMenu";
import { MyProfileFragment, type NavbarConfigFragment } from "@gql-schema";
import { useOnLogin } from "@shared/components/Navigation/elements/use-on-login";
import { ButtonOpaque } from "@ui/components/Button/ButtonOpaque";
import { NavBarLoginButtonMobile } from "@ui/components/NavBar/nav-bar-login-button-mobile";
import { LoggedUserMenu } from "./logged-user-menu";
import { MainNavBarMobileMenuItem } from "./main-nav-bar-mobile-menu-item";

const LOGIN_ITEM = USER_MENU.login;

interface Props {
    activeMenuItemIndex?: number;
    className?: string;
    hideLandingPageMenu?: boolean;
    navbarData?: NavbarConfigFragment;
    profile: MyProfileFragment | null;
    showFullCustomerZone?: boolean;
}

export function MobileMenu(props: Props) {
    const onLogin = useOnLogin(props.profile);

    const navbarData = props.navbarData;

    const title = <span className="block uppercase">{CUSTOMER_ZONE_MENU_ITEM.title}</span>;

    return (
        <div className={props.className}>
            {!props.hideLandingPageMenu && (
                <ul>
                    {navbarData?.sections.map((item, index) => (
                        <MainNavBarMobileMenuItem
                            data={navbarData}
                            href={item.href}
                            index={index}
                            isActive={index === props.activeMenuItemIndex}
                            key={index}
                            title={item.label}
                        />
                    ))}
                </ul>
            )}
            {!props.profile?.email || !props.profile.isLogged ? (
                <div className="flex items-center justify-between border-t border-green-secondaryDark p-6">
                    <NavBarLoginButtonMobile
                        analyticsCallback={!props.profile?.email ? LOGIN_ITEM.analyticsCallback : undefined}
                        menuKey={LOGIN_ITEM.menuKey}
                        onClick={props.profile?.email ? onLogin : undefined}
                        route={!props.profile?.email ? LOGIN_ITEM.route : undefined}
                        title={
                            props.profile?.name ? (
                                <>
                                    {title}
                                    <span className="block text-10 leading-normal opacity-60">
                                        {
                                            !`přihlášen ${
                                                props.profile.surname
                                                    ? `${props.profile.name} ${props.profile.surname}`
                                                    : props.profile.surname
                                            }`
                                        }
                                    </span>
                                </>
                            ) : (
                                title
                            )
                        }
                    />
                    <ButtonOpaque route="watch-wolf-zone/entry-page" $py={8} $px={16} $fontSize={14} $fontWeight={500}>
                        Hlídací vlk
                    </ButtonOpaque>
                </div>
            ) : (
                <LoggedUserMenu
                    className={!props.hideLandingPageMenu ? "mt-4" : undefined}
                    showFullCustomerZone={props.showFullCustomerZone}
                    userName={props.profile.name ?? "Můj Kalkulátor"}
                />
            )}
        </div>
    );
}
