import { type NavbarConfigItemFragment } from "@gql-schema";
import { MobileMenuItemClickable } from "@ui/components/NavBar/styles/MobileMenuItemClickable";
import { COLORS } from "@ui/theme/colors";

type MobileSubmenuDropDownLevel1TitleProps = NavbarConfigItemFragment;

export function MobileSubmenuDropDownLevel1Title(props: MobileSubmenuDropDownLevel1TitleProps) {
    try {
        return (
            <MobileMenuItemClickable
                $color={COLORS.green.default}
                $minHeight={44}
                $pl={48}
                $textTransform="none"
                $isFullWidth
                href={props.href}
            >
                <div className="mobile-menu-title text-14 font-semibold leading-tight">{props.label}</div>
            </MobileMenuItemClickable>
        );
    } catch {
        return null;
    }
}
