import {
    MobileMenuItemClickable,
    MobileMenuItemClickableProps,
} from "@ui/components/NavBar/styles/MobileMenuItemClickable";
import { CLASSES } from "@uxf/core/constants/classes";
import { cx } from "@uxf/core/utils/cx";
import { ReactNode, memo } from "react";

interface Props extends MobileMenuItemClickableProps {
    isActive?: boolean;
    menuKey?: string;
    title: string;
    startIcon?: ReactNode;
}

export const NavBarMobileMenuItem = memo<Props>((props) => {
    const { className, isActive, menuKey, startIcon, title, ...restProps } = props;

    return (
        <MobileMenuItemClickable
            className={cx(isActive && CLASSES.IS_ACTIVE, className)}
            data-menukey={menuKey}
            {...restProps}
        >
            {!!startIcon && <div className="mr-2 shrink-0">{startIcon}</div>}
            <div className="font-16 leading-tight">{title}</div>
        </MobileMenuItemClickable>
    );
});

NavBarMobileMenuItem.displayName = "NavBarMobileMenuItem";
