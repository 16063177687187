import { type ReactNode } from "react";

type Props = { className?: string; children: ReactNode };

export function NavBarContainerMobile(props: Props) {
    return (
        <div className={`flex h-[76px] w-full items-center pt-4 md:hidden ${props.className ?? ""}`}>
            {props.children}
        </div>
    );
}
